import React from "react";
import Template from '../components/Template/Template';
import Faq from '../sections/Faq/Faq';

const Marki = () => {

   return (
        <Template path="/marka-brother">
           
           <h1 className="ch1">Brother - skup tonerów</h1>
            <section id="pSell" className="ccon">
                <p>Wykorzystywane w firmach, w domach prywatnych, a nawet w szkołach &ndash; drukarki i oryginalne tonery Brother cieszą się dużą popularnością. Nie zawsze jednak zakupione wkłady zostają użyte. Czasami o nich zapominamy, czasami okazuje się, że poprzedni kartridż wystarczył na znacznie dłużej, niż się spodziewaliśmy. Wkłady nie są, niestety, wieczne &ndash; z czasem zasychają. Co więc zrobić w sytuacji, gdy mamy zapas, którego nie potrzebujemy?</p>
                <p>Warto zgłosić się do nas! Skup pełnych tonerów Brother odbierze od Państwa oryginalne produkty, dokona wyceny i zaoferuje Wam dobrą cenę. W ten sposób zwróci się część kosztów.</p>
                <p>Na terenie Warszawy i okolic po wkłady przyjedzie do Państwa nasz kierowca, natomiast do innych miast (Koszalin, Szczecin, Ruda Śląską i wiele innych!) wyślemy kuriera.</p>
            </section>
    

        </Template>
    )
}

export default Marki
